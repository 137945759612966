@import "../_base/_variables";

a,
.btn-link {
    text-decoration: none;
}

.btn {
    &-primary,
    &-secondary,
    &-success,
    &-danger,
    &-warning,
    &-info,
    &-light,
    &-dark,
    &-outline-primary,
    &-outline-secondary,
    &-outline-success,
    &-outline-danger,
    &-outline-warning,
    &-outline-info,
    &-outline-light,
    &-outline-dark {
        width: 100%;
        padding: 8px 20px;
        border-radius: 100px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

        @include font-size($font-regular);

        font-weight: $font-weight--medium;
        font-family: $font-family--primary;
    }

    &-outline-primary {
        border: 2px solid;
        background: none;
    }

    &-outline-secondary,
    &-outline-light {
        border: 2px solid;
        background: none;
    }

    &-primary,
    &-secondary {
        color: $white;

        &:hover {
            color: $white;
        }

        &:focus {
            color: $white;
        }
    }

    /* &-lg {
        padding: 12px;
    }

    &-sm {
        padding: 5px;
    } */
}
