/* // BS Checkbox size override
input[type=checkbox] {
    transform: scale(1.5);
    cursor: pointer;
    margin-left: -20px !important;

    & + label {
        cursor: pointer;
        margin-left: 10px;
    }
}

.form-date {
    background-image: url(../../images/calendar.png);
    background-repeat: no-repeat;
    text-align: left;
    background-position: right 15px top 13px;

    &::-webkit-calendar-picker-indicator {
        opacity: 0;
    }

    &::-moz-calendar-picker-indicator {
        display: none;
    }
}

// BS Forms override
.form-control,
.form-label,
.form-check-label,
.form-select {
    @include font-size($font-regular);
}

.form-control,
.form-select {
    min-height: 56px;
}

.form-label {
    font-weight: $font-weight--regular;
    color: $black-stone;
}
*/
