.main-header {
    width: 100%;
    z-index: 1045;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    animation: 0.4s ease forwards;
    position: fixed;
    top: 0;

    &--primary {
        background: rgba($primary, 0.95);
        background: $primary;
        box-shadow: 0 6px 15px 0 rgba($primary, 0.04);

        .main-header__container {
            border-bottom: none;
        }
    }

    &--no-hero {
        background: $primary;
        position: sticky;
        top: 0;
    }

    &__container {
        padding: 10px 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media-breakpoint-down(lg) {
            padding: 10px 15px;
        }
    }

    &__logo {
        @include media-breakpoint-down(lg) {
            z-index: 2;
        }

        a {
            svg {
                width: 150px;
                height: 70px;
                color: $white;
            }
        }
    }

    &__menu-links {
        > button {
            display: none;
            border: none;
            background: none;
            padding: 0;

            @include media-breakpoint-down(lg) {
                display: block;
                z-index: 2;
                position: relative;
            }

            .burger-icon {
                width: 30px;
                height: 30px;
                color: $white-lilac;
            }

            .close-icon {
                width: 30px;
                height: 30px;
                color: $white-lilac;
                display: none;
            }

            &.active {
                .burger-icon {
                    display: none;
                }

                .close-icon {
                    display: flex;
                }
            }
        }

        > ul {
            display: flex;
            align-items: center;
            margin: 0;
            padding: 0;
            list-style: none;

            @include media-breakpoint-down(lg) {
                visibility: hidden;
                -webkit-transition-duration: 0.4s;
                transition-duration: 0.4s;
                animation: 0.4s ease forwards;
                left: -100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: fixed;
                top: 0;
                width: 100%;
                height: 100%;
                background: $primary;
                min-height: 470px;
                padding-top: 40px;

                &.active {
                    left: 0;
                    visibility: visible;
                }
            }

            > li {
                padding: 17px 0;
                margin-left: 5px;

                &:last-child {
                    margin-left: 10px;
                }

                @include media-breakpoint-down(lg) {
                    padding: 15px 0;
                    margin-left: 0;
                }

                > a {
                    &:not(.btn) {
                        display: flex;
                        align-items: center;
                        padding: 7px 18px;
                        color: $white;

                        @include font-size($font-regular);

                        font-weight: $font-weight--medium;
                        transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

                        @include media-breakpoint-down(lg) {
                            @include font-size($font-big);
                        }

                        &:hover {
                            color: rgba($white, 0.8);
                            background-color: rgba($white, 0.1);
                            border-radius: 100px;
                        }
                    }
                }

                /* > ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    position: absolute;
                    top: 100%;
                    background-color: $white;
                    border-radius: 8px;
                    min-width: 230px;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    box-shadow: 0 25px 70px 0 rgba($primary, 0.07);
                    opacity: 0;
                    pointer-events: none;
                    transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);

                    li {
                        > a {
                            display: flex;
                            align-items: center;
                            padding: 7px 16px;

                            @include font-size($font-regular);

                            font-weight: $font-weight--medium;
                        }
                    }
                } */
            }
        }
    }
}
