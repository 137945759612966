/* @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');
*/
//Bootstrap enable responsive font-size
$enable-responsive-font-sizes: true;

@font-face {
    font-family: 'GT Walsheim Pro';
    src: url('../../font/GTWalsheimPro-Medium.woff2') format('woff2'), url('../../font/GTWalsheimPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT Walsheim Pro';
    src: url('../../font/GTWalsheimPro-Regular.woff2') format('woff2'), url('../../font/GTWalsheimPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT Walsheim Pro';
    src: url('../../font/GTWalsheimPro-Black.woff2') format('woff2'), url('../../font/GTWalsheimPro-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT Walsheim Pro';
    src: url('../../font/GTWalsheimPro-Light.woff2') format('woff2'), url('../../font/GTWalsheimPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT Walsheim Pro';
    src: url('../../font/GTWalsheimPro-Bold.woff2') format('woff2'), url('../../font/GTWalsheimPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT Walsheim Pro Condensed';
    src: url('../../font/GTWalsheimPro-CondensedBold.woff2') format('woff2'), url('../../font/GTWalsheimPro-CondensedBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT Walsheim Pro Ultra';
    src: url('../../font/GTWalsheimPro-UltraBold.woff2') format('woff2'), url('../../font/GTWalsheimPro-UltraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT Walsheim Pro';
    src: url('../../font/GTWalsheimPro-Thin.woff2') format('woff2'), url('../../font/GTWalsheimPro-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
