
.paginator {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    margin: 0;
    list-style: none;

    li {
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }

        .active {
            width: 35px;
            height: 35px;
            text-align: center;
            background: $primary;
            color: $white;
            border-radius: 50%;
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        a {
            width: 35px;
            height: 35px;
            text-align: center;
            border: 1px solid $primary;
            border-radius: 50%;
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 20px;
                height: 20px;
                color: $primary;

                &.chevron-left {
                    transform: rotate(180deg);
                }
            }
        }
    }
}
