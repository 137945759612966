/*** Typography ***/
//Responsive font size
@import "~rfs/scss";

$font-family--primary       :   'GT Walsheim Pro', sans-serif;
//$font-family--secondary     :   'Nunito Sans', sans-serif;

// Font weight
$font-weight--extra-light   :   200;
$font-weight--light         :   300;
$font-weight--regular       :   400;
$font-weight--medium        :   500;
$font-weight--semibold      :   600;
$font-weight--bold          :   700;
$font-weight--extra-bold    :   800;
$font-weight--black         :   900;

// Font sizes
$font-xxxx-large            :   68px;
$font-xxx-large             :   60px;
$font-xx-large              :   48px;
$font-x-large               :   40px;
$font-large                 :   36px;
$font-xx-big                :   30px;
$font-x-big                 :   24px;
$font-big                   :   20px;
$font-medium                :   18px;
$font-regular               :   16px;
$font-small                 :   14px;
$font-x-small               :   12px;
$font-xx-small              :   11px;
$font-xxx-small             :   10px;
$font-xxxx-small            :   9px;

/*** Colors ***/

$white              :   #fff;
$black              :   #000;
$black-stone        :   #212529;
$black-oak          :   #4e4e4e;

$galactic-blue      :   #140342;
$electric-violet    :   #6440fb;

$blue-gem           :   #191687;
$purple-heart       :   #5434d9;

$darkest-grey       :   #495057;
$slate-grey         :   #74848e;
$explosive-grey     :   #c4c4c4;
$silver             :   #bababa;
$light-grey         :   #f8f8f8;
$light-concrete     :   #f7f8f9;
$coconut-white      :   #f5f7fe;
$dust               :   #bcbcbc;

$teal-blue          :   #2c6984;

$white-lilac        :   #f5f8fa;
//$error              :   #f03d3e;
$spring-green       :   #00ff84;
$success            :   #34c800;
$alert              :   #f1a32f;
$warning            :   #f03d3e;
$explosive-grey     :   #c4c4c4;
$snowbank           :   #e9e9e9;

$grapefruit-yellow  :   #e59819;

$primary            :   $galactic-blue;
$secondary          :   $electric-violet;
