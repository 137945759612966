@import "../_base/_typography";
@import "../_base/_variables";
@import "~bootstrap";

//Bootstrap breakpoints mixins
@import "bootstrap/scss/_functions";
@import "bootstrap/scss/_variables";
@import "bootstrap/scss/mixins/_breakpoints";

//Common files
@import "../_elements/_buttons";
@import "../_elements/_forms";
@import "../_elements/_pagination";

// Header
@import "../_components/_header";
@import "../_components/_footer";

html {
    height: 100%;
}

body {
    min-height: 100%;
    font-family: $font-family--primary;
}

.maintenance {
    font-weight: $font-weight--semibold;
    padding: 20px;
    text-align: center;
    text-transform: uppercase;
    background: $alert;
    color: $white;
}

.not-found {
    .card {
        border: none !important;
    }

    article {
        margin: 250px 0;

        @include media-breakpoint-down(lg) {
            margin: 100px 0;
        }

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h1 {
            color: $black-stone;
            text-align: center;

            @include font-size($font-xxxx-large);

            font-weight: $font-weight--semibold;
        }

        h2 {
            text-align: center;
        }

        p {
            color: $black-oak;
            text-align: center;

            @include font-size($font-medium);

            font-weight: $font-weight--regular;
        }
    }
}

.cookie-policy {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    z-index: 100;
    background: rgba($primary, 0.95);
    backdrop-filter: blur(4px);
    border-radius: 10px;
    position: fixed;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);

    @include media-breakpoint-down(sm) {
        bottom: 0;
        border-radius: 10px 10px 0 0;
    }

    p {
        margin-top: 0;
        margin-bottom: 15px;
        color: $white;
        line-height: 1.6;

        @include font-size($font-small);

        a {
            color: $white;
            font-weight: $font-weight--semibold;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
                color: $white;
            }
        }
    }
}
